import React, { Component } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './main.scss';
import {graphql, StaticQuery} from 'gatsby';
import {MDBCard, MDBCardBody, MDBCardTitle, MDBIcon} from "mdbreact";
import StyledBackgroundSection from "../components/background";
import MenuContainer from "../components/menu-container";

class ContactPage extends Component {
    
    // initMap = () => {
    //     const location = { lat: 52.711853, lng: 5.283132 };
    //     const map = new window.google.maps.Map(document.getElementById('map'), {
    //         center: location,
    //         zoom: 14,
    //     });
    //
    //     new window.google.maps.Marker({position: location, map: map});
    // };
    //
    // componentDidMount() {
    //     window.initMap = this.initMap;
    // }
    
    render () {
        return (
            <StyledBackgroundSection className="contact-background">
                <div className="contact">
                    <MenuContainer />
                    <div className="container">
                        <div className="row justify-content-end">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-4 align-self-end">
                                <MDBCard className="mt-5">
                                    <MDBCardBody>
                                        <MDBCardTitle className="tenby-five">Contact</MDBCardTitle>
                                        <address>
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td className="border-primary text-center pr-0"><MDBIcon icon="map-marked-alt" /></td>
                                                    <td className="border-primary">Piet Smitstraat 15<br/>1602VA Enkhuizen</td>
                                                </tr>
                                                <tr>
                                                    <td className="border-primary text-center pr-0">
                                                        <MDBIcon icon="phone-alt" /> <br/>
                                                    </td>
                                                    <td className="border-primary">0228 315 314</td>
                                                </tr>
                                                <tr>
                                                    <td className="border-primary text-center pr-0">
                                                        <MDBIcon fab icon="facebook-f" /> <br/>
                                                    </td>
                                                    <td className="border-primary">
                                                        <a target="_blank"
                                                           rel="noopener noreferrer"
                                                           href="https://www.facebook.com/Michelles-Haarsalon-109411123909275/">
                                                            Facebook
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border-primary text-center pr-0">
                                                        <MDBIcon icon="envelope" /> <br/>
                                                    </td>
                                                    <td className="border-primary">
                                                        <a href="mailto:info@michelleshaarsalon.nl">
                                                            info@michelleshaarsalon.nl
                                                        </a>
                                                    </td>
                                        
                                                </tr>
                                                </tbody>
                                            </table>
                                        </address>
                                        {/*<div id="map"></div>*/}
                                    </MDBCardBody>
                                </MDBCard>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<Helmet>*/}
                    {/*<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyB7D5BwK6AaAGrw88TSkEOYVDFdw9_XNsA&callback=initMap" async defer />*/}
                {/*</Helmet>*/}
            </StyledBackgroundSection>
        );
    }
}

export default props => (
    <StaticQuery
        query={graphql`
          query {
            desktop: file(relativePath: { eq: "salon.jpg" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1400) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            logo: file(relativePath: { eq: "logo.png" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 300) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `}
        render={data => <ContactPage data={data} {...props} />}
    />
);